<template>
  <div class="content">
    <a-row>
      <a-form layout="inline">
        <a-form-item label="查询时间">
          <a-date-picker v-model="query.time"/>
        </a-form-item>

        <a-form-item label="小区名称">
          <a-input v-model="query.centerName" placeholder="请输入小区名称"/>
        </a-form-item>

        <a-form-item label="街道">
          <a-select
              v-model="query.streetCode"
              placeholder="请选择街道"
              style="width: 200px;"
          >
            <a-select-option :key="-1" :value="undefined">全部</a-select-option>
            <a-select-option v-for="street in streetList" :key="street.value" :value="street.value">
              {{ street.label }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item>
          <a-button type="primary" @click="customQuery">查询</a-button>
        </a-form-item>
      </a-form>
    </a-row>

    <a-row align="bottom" justify="space-between" style="margin-top: 12px;" type="flex">
      <a-col>
        共 {{ pagination.total }} 条告警数据：{{ statistic.villageCount }}个小区
      </a-col>

      <a-col>
        <a-space>
          <a-button type="primary" @click="showNoDataVillage">查看无数据小区</a-button>
          <a-button type="primary" @click="$notification['warning']({ message: '敬请期待'})">下载该表</a-button>
        </a-space>
      </a-col>
    </a-row>

    <a-row style="margin-top: 24px;">
      <a-table :columns="columns" :data-source="data" :loading="loading"
               :pagination="pagination" @change="handleTableChange">
        <span slot="trashType" slot-scope="text">
          <a-tag :color="trashType[text].color">{{ trashType[text].name }}</a-tag>
        </span>

        <span slot="represent" slot-scope="text">
          <a-badge :text="text" status="error"/>
        </span>

        <span slot="houseCode" slot-scope="text, record">
          {{ record.house ? record.house.houseName : text }}
        </span>

        <span slot="deviceCode" slot-scope="text, record">
          {{ record.device ? record.device.deviceName + ' ' + record.device.deviceType : text }}
        </span>

        <span slot="action" slot-scope="text, record">
          <a-button type="primary" @click="$notification['warning']({ message: '敬请期待'})">详情</a-button>
        </span>
      </a-table>
    </a-row>

    <no-data-village ref="noDataVillage"/>
  </div>
</template>

<script>
import NoDataVillage from "./components/noDataVillage"
import {trashName, trashType} from "@/common/config";
import {mapState} from "vuex";
import {formatToDateBegin, formatToDateEnd, formatToDateWithTime} from "@/utils/time";
import {queryCleanHouse, queryDevice, queryVillageDataException} from "@/api/village";
import _ from "lodash";
import moment from "moment";

export default {
  name: "dataAlarm",
  components: {NoDataVillage},
  data() {
    return {
      trashType,
      query: {
        time: undefined,
        centerName: undefined,
        areaCode: undefined,
        streetCode: undefined,

        sort: undefined,
        order: undefined
      },
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        showTotal: (total, range) => `总共 ${total} 条数据`,
      },
      columns: [
        {
          title: '小区名称',
          dataIndex: 'centerName',
          key: 'centerName',
          align: 'center',
          // sorter: true,
        },
        {
          title: '所属街道',
          dataIndex: 'streetName',
          key: 'streetName',
          align: 'center',
          // sorter: true,
        },
        {
          title: '异常描述',
          dataIndex: 'represent',
          key: 'represent',
          scopedSlots: {customRender: 'represent'},
          align: 'center',
          // sorter: true,
        },
        // {
        //   title: '垃圾类别',
        //   dataIndex: 'trashType',
        //   key: 'trashType',
        //   scopedSlots: {customRender: 'trashType'},
        //   align: 'center',
        //   // sorter: true,
        // },
        // {
        //   title: '重量（KG）',
        //   key: 'weight',
        //   dataIndex: 'weight',
        //   scopedSlots: {customRender: 'weight'},
        //   align: 'center',
        //   // sorter: true,
        // },
        {
          title: '清洁屋名称',
          key: 'houseCode',
          dataIndex: 'houseCode',
          scopedSlots: {customRender: 'houseCode'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '设备名称',
          key: 'deviceCode',
          dataIndex: 'deviceCode',
          scopedSlots: {customRender: 'deviceCode'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '发生时间',
          dataIndex: 'exceptionTime',
          key: 'exceptionTime',
          scopedSlots: {customRender: 'exceptionTime'},
          align: 'center'
        },
        // {
        //   title: '查看',
        //   key: 'action',
        //   scopedSlots: {customRender: 'action'},
        //   align: 'center'
        // },
      ],
      data: [],
      loading: true,
      statistic: {
        villageCount: 0
      },
      cache: {}
    }
  },
  mounted() {
    this.query.time = moment()
    this.queryDataAlarmList()
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.query.sort = sorter.field
      this.query.order = sorter.order

      this.queryDataAlarmList()
    },
    async queryDataAlarmList() {
      this.loading = true

      let data = await queryVillageDataException(this.convertQuery())
      _.forEach(data.result.content, (value) => {
        value.exceptionTime = moment(value.exceptionTime).format('YYYY-MM-DD HH:mm:ss')
      })
      this.convertPagination(data.result)

      this.data = this.convertData(data.result.content)

      this.statistic.villageCount = _.uniq(_.map(this.data, data => data.centerCode)).length

      _.forEach(this.data, async data => {
        if (!this.cache[data.houseCode]) {
          this.cache[data.houseCode] = (await queryCleanHouse(data.houseCode)).result
        }
        if (!this.cache[data.deviceCode]) {
          this.cache[data.deviceCode] = (await queryDevice(data.deviceCode)).result
        }

        this.$set(data, 'house', this.cache[data.houseCode])
        this.$set(data, 'device', this.cache[data.deviceCode])
      })

      this.loading = false
    },
    customQuery() {
      this.query.currentPage = 1
      this.queryDataAlarmList()
    },
    showNoDataVillage() {
      this.$refs.noDataVillage.toggleVisible()
    },
    convertQuery() {
      return {
        centerName: this.query.centerName,
        streetCode: this.query.streetCode,
        // startTime: formatToDateBegin(this.query.time[0]),
        // endTime: formatToDateEnd(this.query.time[1]),

        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current,
        sort: this.query.sort,
        order: this.query.order,
      }
    },
    convertPagination(data) {
      this.pagination.current = data.number + 1
      this.pagination.total = data.totalElements
    },
    convertData(data) {
      return _.map(data, (value, index) => {
        return {
          key: value.id,
          ...value,
        }
      })
    },
  },
  computed: mapState({
    streetList: state => state.user.streetList
  })
}
</script>

<style lang="scss" scoped>
.content {
  background-color: white;
  padding: 15px;
}
</style>
